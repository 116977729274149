import React from 'react';
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
// import {MyRoute} from './components/common/PrivateRouter';
// import NotFound from './components/pages/NotFound';
// import Login from './components/pages/Login';
// import App from './App';
import Loadable from 'react-loadable';
import Loading from './routes/Loading';

const Login = Loadable({
    loader: () => import('./components/pages/QRCodeLogin'),
    // loader: () => import('./components/pages/Login'),
    loading: Loading
});

const App = Loadable({
    loader: () => import ('./App'),
    loading: Loading
});

const NotFound = Loadable({
    loader: () => import('./components/pages/NotFound'),
    loading: Loading
});

export default () => (
    <Router>
        <Switch>
            <Route exact path="/" render={() => <Redirect to='/login'/>}/>
            <Route path="/app" component={App}/>
            <Route path="/login" component={Login}/>
            <Route path="/404" component={NotFound}/>
            <Route component={NotFound}/>
        </Switch>
    </Router>
);
