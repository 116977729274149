import React, {Component} from 'react';
import {Spin} from 'antd';
import '../style/loading.less';

class Loading extends Component {
    render() {
        return (
            <div className='loading-spin'>
                <Spin tip='正在载入中...' size='large'/>
            </div>
        );
    }
}

export default Loading;
